<template>
	<GigPageWrapper :gig-id="String($attrs.id)" current-route="offers" title="Offers">
        <BidAccept :currency-symbol="bid.currency.symbol" :message="popupErrorMessage" :bid="bid" :specialism="specialismChosen" v-if="bid && isShowAccept" v-on:acceptBid="accept(bid)" v-on:closeModal="isShowAccept = $event;popupErrorMessage = '';" />
        <BidDecline :bid="bid" v-if="bid && isShowDecline"  v-on:declineBid="decline(bid)" v-on:closeModal="isShowDecline = $event;" />

        <InlineNotification v-if="isGigCancelled" Heading="Gig cancelled">
        <template slot="body">
            <p class="mt-2 text-justify">Below is a list of artists that were offered this gig. All artists have since been notified of the gigs cancellation. Please navigate to the gig details page using the component below if you wish to see the full detailed breakdown of this cancelled gig.</p>
            <router-link :to="'/gigs/' + $attrs.id" class="flex items-center w-48 mt-1 bg-transparent"><ArrowCircleLeftIcon class="mr-2" color="pink"/>Go to gig details page</router-link>
        </template>
        </InlineNotification>


        <InlineNotification v-else-if="!isGigCancelled && !isGigBooked" Heading="Book your artists">
                        <template slot="body">
                                            <p class="mt-2 text-justify">Below is a list of artists that are available for this gig. Simply click “thumbs up” to book the artist you want. This will send the confirmation to your chosen artist and the gig is booked.</p>
                        </template>
        </InlineNotification>

        <InlineNotification v-else-if="!isGigCancelled && isGigBooked" Heading="Gig booked">
            <template slot="body">
                <p class="mt-2 text-justify">This gig is now booked. Once the gig has passed, you will be able to sign-off this gig and receive an invoice from the artist.</p>
            </template>
        </InlineNotification>

        <a class="hidden gp_cta disabled">Add artists using my shortlist</a>
        <div v-if="this.isLoaded && !gigStarted">
            <div v-if="groupedBids.length && !isGigBooked">
                <div class="flex items-center justify-between border-b border-gray-200 bid-tabs ">
                    <nav class="flex items-center justify-between -mb-px overflow-x-auto overflow-y-hidden">
                        <div class="grid items-start grid-flow-row gap-4 grow"  v-for="(groupedBid, index) in groupedBids" :class="groupedBidMoreInfoId == groupedBid.info.id ? 'bid_tab_active' : ''" :key="'moreinfo_' + index">
                            <a  @click="groupedBidMoreInfoId = groupedBid.info.id; initData()" class="grid items-center justify-center grid-flow-col gap-2">
                                <span>{{groupedBid.info.name}}</span>
                                <span class="bg-indigo-100 text-indigo-600 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"> {{groupedBid.count}} </span>
                            </a>
                        </div>
                    </nav>
                </div>

                <div class="flex flex-col mt-4 "  v-for="(grouping, index) in groupedBids.filter(b => b.info.id == groupedBidMoreInfoId)"  :key="'moreinfogroup_' + index">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"  :class="groupedBidMoreInfoId == grouping.info.id ? 'block' : 'hidden'" v-if="!isGigBooked && bids && bids.length > 0">
                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div class="max-w-4xl overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                                <table class="min-w-full text-left divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                class="py-3 pl-6 pr-2 text-xs font-medium text-gray-500 uppercase"
                                            >Make choice</th>
                                            <th
                                                scope="col"
                                                class="px-2 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase marker:"
                                            >
                                                Artist
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-0 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                            ></th>
                                            <th
                                                scope="col"
                                                class="px-2 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                            >
                                                Fee 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-sm bg-white divide-y divide-gray-200">
                                        <tr v-for="(bid, index) in grouping.bids" :key="index" :row="bid" :data-bid-id="bid.id">
                                            <td class="p-4" >
                                                <a :href="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()" target="_blank">
                                                    <img :src="bid.artist.image ? (bid.artist.conversions && bid.artist.conversions.thumb ? bid.artist.conversions.thumb : bid.artist.image.url) : require('@/assets/images/placeholder.svg')" :alt="bid.artist.name" class="w-40 border-2 rounded-xl min-h-[160px] object-cover" />
                                                </a>
                                                
                                            </td>
                                            <td class="flex-col text-left ">
                                                <div class="flex flex-col py-2">
                                                    <div class="cursor-pointer flex flex-col gap-4 items-start" @click.prevent="navigate('/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase())">
                                                        <span>{{ bid.artist.name }}</span>
                                                        <span class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-xl bg-[#0D1C43] text-white" v-if="bid.artist && bid.artist.specialities && bid.artist.specialities.length">{{bid.artist.specialities[0].tier.name}}</span>
                                                    </div>
                                                    <p v-if="bid.artist.based_in && bid.artist.based_in.preview" class="mt-4 text-xs font-normal">Based in {{ bid.artist.based_in.preview }}</p>
                                                    <a target="_blank" :href="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()" class="text-sm font-normal">
                                                        <div v-if="bid.artist && bid.artist.specialities && bid.artist.specialities.length" v-show="bid.artist.specialities[0].more_info.length !== 0" class="my-2">
                                                            <strong class="mb-2 text-sm font-medium mt-2">Specialisms:</strong>
                                                            <div v-for="(info, index) in bid.artist.specialities[0].more_info" :key="index">
                                                                <p class="text-sm">{{ info.name }}</p>
                                                            </div>
                                                        </div>
                                                        <div v-if="bid.artist && bid.artist.specialities && bid.artist.specialities.length" v-show="showCovers(bid)" class="mb-2">
                                                            <strong class="mt-6 mb-2 text-sm font-medium">Covers/Originals:</strong>
                                                            <div v-for="(cover, index) in bid.artist.specialities[0].covers" :key="index">
                                                                <p class="text-sm">{{ cover.name }}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div class="mr-6">
                                                    <div  v-if="showGenres(bid)">
                                                        <td
                                                            class="py-2 text-sm text-gray-500 lg:table-cell"
                                                        >
                                                            <span
                                                                class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-xl bg-gp_pink-default-400 text-white"
                                                                >{{
                                                                    artistGenreFirst(
                                                                        bid.artist.specialities[0].genres[0]
                                                                    )
                                                                }}</span
                                                            >

                                                            <span class="relative inline-flex flex-col items-center group">
                                                                <span
                                                                    class="text-sm font-medium underline cursor-pointer underline-offset-2"
                                                                    >+{{
                                                                        bid.artist.specialities[0].genres
                                                                            .length - 1
                                                                    }}
                                                                    more</span
                                                                >
                                                                <div
                                                                    class="absolute bottom-0 z-10 flex-col items-center hidden mb-6 group-hover:flex"
                                                                >
                                                                    <span
                                                                        class="w-48 p-4 bg-black rounded-sm shadow-lg"
                                                                    >
                                                                        <ul
                                                                            class="list-none"
                                                                        >
                                                                            <li
                                                                                v-for="genre in bid.artist.specialities[0].genres"
                                                                                :key="
                                                                                    genre.id
                                                                                "
                                                                                class="text-white "
                                                                            >
                                                                                {{
                                                                                    genre.name
                                                                                }}
                                                                            </li>
                                                                        </ul>
                                                                    </span>
                                                                    <div
                                                                        class="w-3 h-3 -mt-2 rotate-45 bg-black"
                                                                    ></div>
                                                                </div>
                                                            </span>
                                                        </td>
                                                    </div>
                                                    <a
                                                        target="_blank"
                                                        :href="
                                                            '/artist-directory/' +
                                                                bid.artist.id +
                                                                '/' +
                                                                slugify(
                                                                    bid.artist.name
                                                                ).toLowerCase()
                                                        "
                                                        class="text-sm font-normal"
                                                    >
                                                        <div  v-if="bid.artist && bid.artist.specialities && bid.artist.specialities.length"
                                                            v-show="showVocals(bid)"
                                                        >
                                                            <strong
                                                                class="mb-2 text-sm font-medium"
                                                                >Vocals:</strong
                                                            >
                                                            <div
                                                                v-for="(vocal,
                                                                index) in bid.artist.specialities[0].vocals"
                                                                :key="index"
                                                            >
                                                                <p class="text-sm">
                                                                    {{ vocal.name }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    </div>                                                    
                                                    
                                                </div>
                                            </td>
                                            <td class="text-left ">
                                                <div class="mr-6">
                                                    <div class="items-center justify-center mt-4 space-y-1 text-center">
                                                        <div class="flex items-center space-x-3">
                                                        <a v-if="favedArtists.includes(bid.artist.id) == false" class="cursor-pointer text-gp_pink-default-500" @click="$refs.faveModal.toggleModal();;addArtist = bid.artist;">
                                                            <StarIcon fill="none" stroke="currentColor" title="Add to favourites list" class="w-6 h-6"/>
                                                        </a>
                                                        <a v-if="favedArtists.includes(bid.artist.id) == true" class="cursor-pointer text-gp_pink-default-500" @click="$refs.faveModal.toggleModal();;addArtist = artist; privateVenues = artist.private_venues; privateVenueIds = setPrivateArtistIds(artist.private_venues)">
                                                            <StarIcon title="Already in favourites list" class="w-6 h-6"/>
                                                        </a>
                                                        <p @click="$refs.faveModal.toggleModal();;addArtist = artist;" class="text-sm font-normal cursor-pointer">Add to favourites</p>
                                                        </div>
                                                        <div class="flex items-center space-x-4">
                                                        <a target="_blank" :href="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()">
                                                            <EyeIcon fill="none" stroke="currentColor" class="w-5 h-6 cursor-pointer text-gp_pink-default-500"/>
                                                        </a>
                                                        <a target="_blank" :href="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()" class="text-sm font-normal">View Profile</a>
                                                        </div>
                                                    </div>

                                                </div>

                                            </td>
                                            <td class="py-4 text-left">
                                                <div v-if="bid.declined" class="ml-2 textleft py-2">
                                                    Availability declined
                                                </div>
                                                <div v-else-if="bid.cancelled" class="ml-2 text-left py-2">
                                                    Offer cancelled
                                                </div>
                                                <div v-else-if="bid.gig.cancelled" class="ml-2 text-left py-2">
                                                    Gig cancelled
                                                </div>
                                                <div v-else-if="bid.awaiting_artist_bid" class="ml-2 text-left py-2">
                                                    Availability pending
                                                </div>
                                                <div v-else-if="bid.awaiting_venue_final_acceptance" class="mt-2 ml-1 text-left">
                                                    <div>
                                                        <span v-if="bid.artist">
                                                            
                                                            <span v-show="bid.artist.deleted_at">[Artist deleted]</span> 
                                                            <span v-if="bid.artist">{{ bid.artist.name }}</span> is available.<br/> 
                                                       
                                                        </span>
                                                        
                                                    </div>
                                                    <div class="flex flex-row mt-4 mb-3 space-x-8">
                                                        <a @click="showAccept(bid, grouping.info.id != 0 ? grouping.info.name : '')" title="Accept artist" class="mr-2 text-green-600 hover:text-green-800 flex items-center gap-4"><ThumbUpIcon/> Book artist </a> <a  class="text-red-500 hover:text-black" v-show="false"  title="Remove artist" @click="showDecline(bid)"><ThumbDownIcon/></a>
                                                    </div> 
                                                </div>
                                                <div v-else-if="bid.awaiting_artist_final_acceptance" class="ml-2">
                                                    <strong>You have accepted this artist,<br/> awaiting artist's final confirmation.</strong>
                                                </div>
                                              <div v-else-if="bid.accepted && bid.cancellation_request_open">
                                                <span class="text-red-500 flex items-center"><XIcon class="mr-2"/>Artist cancelled booking </span>
                                              </div>
                                              <div v-else-if="bid.accepted">
                                                <span class="text-green-500 flex items-center"><CheckIcon class="mr-2"/>Artist booked </span>
                                              </div>
                                                <div v-else>
                                                </div>
                                                <p v-show="!bid.awaiting_venue_final_acceptance && !bid.accepted && !bid.min_fee_met && bid.artist_min_fee && hasMinFeeField" 
                                                    class="mt-4 ml-2 text-xs text-red-500">
                                                    <strong>Artist minimum fee of {{item.currency.symbol}}{{bid.artist_min_fee}} not met.</strong>
                                                </p>
                                                <strong><span class="ml-2">{{item.currency.symbol}}{{bid.fee}} <span v-if="bid.artist.vat_number"> + VAT</span></span></strong>
                                                <a class="pl-2 ml-2 text-gp_pink-default-500" 
                                                    v-if="!bid.signed_off_at && !isGigCancelled"
                                                    @click="editBidFee(bid)">Amend</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Pagination
                                    @changePage="(n) => paginate_currPage = n"
                                    :paginate_from="paginate_from"
                                    :paginate_to="paginate_to"
                                    :paginate_total="paginate_total"
                                    :paginate_currPage="paginate_currPage"
                                    :paginate_lastPage="paginate_lastPage">
                                </Pagination>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <NoDataMsgWrapper>{{ offerMessage }}</NoDataMsgWrapper>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="flex flex-col mt-4 " v-if="!isGigBooked && bids && bids.length > 0">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div class="max-w-4xl overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                                <table class="min-w-full text-left divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase">
                                                Make choice
                                            </th> 
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase">
                                            </th> 
                                            <th
                                                scope="col"
                                                class="px-2 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase marker:">
                                                Artist
                                            </th> 
                                            <th
                                                scope="col"
                                                class="px-0 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">               
                                                Fee 
                                            </th> 
                                        </tr>
                                    </thead>
                                    <tbody class="text-sm bg-white divide-y divide-gray-200">
                                        <tr v-for="(bid, index) in bids" :key="index" :row="bid">
                                            <td class="p-4" >
                                                <div v-if="bid.declined">
                                                    <p class="text-red-600">Availability declined</p>
                                                </div> 
                                                <div v-else-if="bid.cancelled">
                                                    <p>Offer cancelled</p>
                                                </div> 
                                                <div v-else-if="bid.gig.cancelled">
                                                    <p>Gig cancelled</p>
                                                </div> 
                                                <div v-else-if="bid.awaiting_artist_bid">
                                                    <p>Availability pending</p>
                                                    <p v-show="!bid.min_fee_met && bid.artist_min_fee" class="mt-1 text-xs text-red-500">Artist minimum fee of {{item.currency.symbol}}{{bid.artist_min_fee}} not met.</p>
                                                </div> 
                                                <div v-else-if="bid.awaiting_venue_final_acceptance" class="flex flex-col ">
                                                    <div>
                                                        <span v-if="bid.artist">{{bid.artist.name}}</span><span v-show="bid.artist.deleted_at">[Artist deleted]</span> Artist is available,<br/>to book this artist, click thumbs up.
                                                    </div>
                                                    <div class="flex flex-row mt-4 space-x-8">                                            
                                                        <a @click="showAccept(bid)" title="Accept artist" class="mr-2 text-green-500 hover:text-green-800"><ThumbUpIcon/></a> <a  class="text-red-500 hover:text-black" v-show="false"  title="Remove artist" @click="showDecline(bid)"><ThumbDownIcon/></a>  
                                                    </div>
                                                </div>
                                                <div v-else-if="bid.awaiting_artist_final_acceptance">
                                                    You have accepted this artist,<br/> awaiting artist's final confirmation.
                                                </div>
                                                <div v-else-if="bid.accepted">
                                                    <span class="flex items-center text-green-500"><CheckIcon class="mr-2"/>Artist booked </span>
                                                </div> 
                                                <div v-else> 
                                                </div>
                                            </td>
                                            <td class="px-4 py-2" >
                                                <router-link class="text-gp_pink-default-500" :to="'/messages/offer/' + bid.gig.id + '/' + bid.id">Message artist</router-link>  
                                            </td> 
                                            <td class="flex-col py-4 mx-2 text-left ">
                                                <router-link v-if="bid.artist" class="block text-gp_pink-default-500" :to="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()">{{bid.artist.name}}</router-link> 
                                                <span v-if="bid.artist.tier.name">{{bid.artist.tier.name}}</span>
                                                <br>
                                                <span v-if="bid.distance">Distance: {{bid.distance}} miles</span> 
                                                <span v-if="bid.matched_tags">{{bid.matched_tags}}</span>
                                            </td> 
                                            <td class="py-4 text-left">
                                                <span>{{item.currency.symbol}}{{bid.fee}}</span>
                                                <a class="pl-2 text-gp_pink-default-500" 
                                                    v-if="!bid.signed_off_at"
                                                    @click="editBidFee(bid)">
                                                    Amend
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Pagination
                                    @changePage="(n) => paginate_currPage = n"
                                    :paginate_from="paginate_from"
                                    :paginate_to="paginate_to"
                                    :paginate_total="paginate_total"
                                    :paginate_currPage="paginate_currPage"
                                    :paginate_lastPage="paginate_lastPage"
                                ></Pagination>
                            </div>
                        </div> 
                    </div>
                </div>            
            </div>
        </div>

        <NoDataMsgWrapper v-else-if="!isGigBooked">Loading offers page. Please wait.</NoDataMsgWrapper>
            <NotificationSmall ref="notification" />
            <template v-if="selectedBid">
                <AmendBidFee 
                    :is-open="showAmendBidFeeModal"
                    :bid="selectedBid"
                    @closed="cancelModal"
                    @updated="onBidFeeUpdated"
                />
            </template> 
            <FaveManagement
            ref="faveModal"
            @forcePageUpdate="forceRerender"
            :Artist="addArtist"
            :private-invites="privateVenues"
            :private-invite-ids="privateVenueIds"
            :private-artist-venues-prop="privateArtistVenues"
            @privateVenueAdded="updatePrivateVenues($event)"
        />
	</GigPageWrapper>
</template>

<script>
    import NotificationSmall from '../../components/notifications/small.vue'; 
    import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue';
    import { apiComputed, apiMethods } from '@/state/helpers'; 
    import InlineNotification from '../../components/notifications/inline.vue'
    import BidAccept from '../../components/modal/BidAccept.vue';
    import BidDecline from '../../components/modal/BidDecline.vue';
    import { ThumbUpIcon, ThumbDownIcon, CheckIcon, ArrowCircleLeftIcon, XIcon  } from "@vue-hero-icons/outline"
    import formatDate from '../../utils/format-date.js';
    import NProgress from 'nprogress';
    import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
    import slugify from 'slugify';
    import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'
    import Pagination from "@/components/elements/Pagination.vue";
    import {EyeIcon, StarIcon } from '@vue-hero-icons/solid';
    import FaveManagement from "../../components/modal/FaveManagement.vue";
    import client from "@/utils/client";


    export default {
        name: "GigBids",
        components:{
        EyeIcon, 
        StarIcon,
        GigPageWrapper,
        NotificationSmall,
        InlineNotification,
        BidAccept,
        BidDecline,
        NoDataMsgWrapper,
        ThumbUpIcon,
        ArrowCircleLeftIcon,
        ThumbDownIcon,
        CheckIcon,
        AmendBidFee,
        Pagination,
        XIcon,
        FaveManagement,
    }, 
        data ()
        {
            return {
                paginate_from: null,
                paginate_to: null,
                paginate_total: null,
                paginate_currPage: 1,
                paginate_lastPage: null,
                popupErrorMessage: "",
                item: null,
                isLoaded: false,
                bids:[],
                specialismChosen: '',
                groupedBidMoreInfoId: 0,
                groupedBids: [],
                bid: null,
                isShowAccept: false,
                isShowDecline: false,
                gigCopy: '',
                pristine: null,
                pristineConfig: {
                    classTo: 'input-group',
                    errorClass: 'has-error',
                    successClass: 'has-success',
                    errorTextParent: 'input-error',
                    errorTextTag: 'span',
                    errorTextClass: 'text-help'
                },
                showAmendBidFeeModal: false,
                showFaveManagement: false,
                addArtist: {},
                selectedBid:null,
                compFaveslists: [],
                favedArtists: [],
                privateArtistVenues: [],
                privateVenues: [],
                privateVenueIds: [],
            }
        },
                
    watch: {
        paginate_currPage() {
            this.filter();
        },
        },
        created() {
            this.initData();
        },
    computed: {
        hasMinFeeField() {
            return new Date(this.item.created_at) > new Date('2024-01-04')
        },
        isGigBooked() {
        return this.$store.getters['gig/isCurrentGigBooked']
        },
        isGigCancelled() {
        return this.$store.getters['gig/isCurrentGigCancelled']
        },
        form: function() {

            let deepCopy = JSON.parse(JSON.stringify(this.item));
            return deepCopy;
        },
        isFavesLoaded(){
            return this.isLoading;
        },
        gigStarted()
        {
            let gigStartDate = new Date(this.item.start);
            gigStartDate.setDate(gigStartDate.getDate());

            return formatDate(new Date(), 'yyyy-MM-dd HH:mm') > formatDate(gigStartDate, 'yyyy-MM-dd HH:mm');
        },
        ...apiComputed, 
            offerMessage(){
                if(!this.item){
                    return ''
                }
                if(!this.item.published){
                    return `Use the "Offer gig to artists" button to publish your gig.  GigPig will then notify you shortly of any artists that have said they are available.`
                }else if(this.item.bids && !this.item.bids.length){
                    return `GigPig is out looking for artists. Once we've made some matches, you'll see them here, and can offer them the gig if they're available.`
                }else if(this.item.bookings && this.item.bookings.length < this.item.max_bookings && this.item.max_bookings > 1){
                    return `This gig requires multiple bookings (${this.item.max_bookings} in total). GigPig is out looking for other artists. Once we've made some matches, you'll see them here, and can offer them the gig if they're available.`
                }else{
                    return ``
                }
            }
        },
    methods:{
            slugify,
            initData(){
                NProgress.start();
                client
                .get("/favourites-lists/")
                .then((resp) => {
                    this.compFaveslists = resp.data.data;
                    this.isLoading = true;
                })
                .finally(() => {
                    NProgress.done();
                });

                this.getGigBidsWithOffers(
                    {
                    id: this.$attrs.id,
                    useTrashedArtists: false,
                    page: this.paginate_currPage,
                    artistTagId: this.groupedBidMoreInfoId ? this.groupedBidMoreInfoId : null,
                    queryParams: [
                        'bids',
                        'bids.artist',
                        'bids.artist.basedIn',
                        'bids.artist.specialities',
                        'bids.artist.specialities.moreInfo',
                        'bids.artist.specialities.covers',
                        'bids.artist.specialities.genres',
                        'bids.artist.specialities.vocals',
                        'venue.address',
                        'bookings',
                        'requestedCancellationBookings'
                    ]
                    }).then((resp) => {
                        this.item = resp;

                        if (this.item.venue.payment_process == "ryft") {

                            let payment_type = this.item.venue.payg_charged_and_billed_monthly ? 'payg_charged_and_billed_monthly' : 'payg';

                            let selected_venue = {
                                credits_remaining: this.item.venue.active_tokens.length,
                                id: this.item.venue.id,
                                name: this.item.venue.name,
                                expiry_year: this.item.venue.expiry_year,
                                expiry_month: this.item.venue.expiry_month,
                                payment_process: this.item.venue.payment_process,
                                currency_id: this.item.venue.currency_id,
                                payment_type: payment_type,
                                has_subscription: this.item.venue.active_subscription && this.item.venue.active_subscription.subscription_plan && this.item.venue.active_subscription.subscription_plan.id !== 1 ? true : false
                            }

                            this.$store.dispatch('account/updateVenueInfo', selected_venue);
                        }

                        if (this.item.artist_tag_id !== undefined && this.item.artist_tag_id !== null) {
                        this.groupedBidMoreInfoId = parseInt(this.item.artist_tag_id)
                        }

                this.paginate_lastPage = this.item.bids.last_page
                this.paginate_currPage = this.item.bids.current_page

                }).finally(() => {
                        this.loadBids();
                        this.$store.dispatch('gig/storeGig', this.item); 
                        this.isLoaded = true;
                        NProgress.done();
                })
            },
            loadBids(){
            this.paginate_from = this.item.bids.from
            this.paginate_to = this.item.bids.to
            this.paginate_total = this.item.bids.total
            this.item.bids = this.item.bids.data

            this.groupedBids = [{
                info: {
                id: 0,
                name: 'All'
                },
                bids: [],
                count: 0
            }]

            this.groupedBids[0].count = this.item.bid_count
            const specialities = this.item.specialities.filter(spec => {
                return spec.more_info.length
            })

            let alreadyCounted = []

            specialities.forEach(spec => {
                if (this.groupedBids.filter(gb => {
                return gb.info.id === spec.more_info[0].id
                }).length === 0) {
                this.groupedBids.push(
                    {
                        info: {
                        id: spec.more_info[0].id,
                        name: spec.more_info[0].name
                        },
                        bids: this.item.bids,
                        count: 0
                    }
                )
                }

                if (!alreadyCounted.filter(ac => {
                return ac.artist_id === spec.artist_id && ac.more_info_id === spec.more_info[0].id
                }).length) {
                this.groupedBids.find(t => {
                    return t.info.id === spec.more_info[0].id
                }).count++

                alreadyCounted.push({artist_id: spec.artist_id, more_info_id: spec.more_info[0].id})
                }

            })

                this.groupedBids[0].bids = this.item.bids.filter(f => f.artist != null);

                if (this.groupedBids.length){
                    this.groupedBids.sort((a, b) => b.bids.length - a.bids.length)
                }

                this.bids = this.item.bids.filter(f => f.artist != null);
            },
        filter(){

            NProgress.start();

            this.getGigBidsWithOffers(
                {
                id: this.$attrs.id,
                useTrashedArtists: false,
                page: this.paginate_currPage,
                artistTagId: this.groupedBidMoreInfoId ? this.groupedBidMoreInfoId : null,
                queryParams: [
                    'bids',
                    'bids.artist',
                    'bids.artist.specialities',
                    'bids.artist.specialities.moreInfo',
                    'venue.address',
                    'bookings',
                    'requestedCancellationBookings'
                ]
                }).then((resp) => {
            this.item = resp;
            this.paginate_lastPage = this.item.bids.last_page
            this.paginate_currPage = this.item.bids.current_page
            }).finally(() => {
                this.loadBids();
                this.isLoaded = true;
                NProgress.done();
            });
        },
            formatDate,
            ...apiMethods,
            showAccept($bid, $specialismChosen){
                this.isShowAccept = true;
                this.bid = $bid;
                this.specialismChosen = $specialismChosen;
            },
            artistGenreFirst(val) {
                return val ? val.name : null;
            },
            showDecline($bid){ 
                this.isShowDecline = true;
                this.bid = $bid;
            },
            accept($bid){ 

                if (window.f24){
                    let resp = window.f24('event', 'wa_page_view', { loc:window.location.origin, CheckOutAmount: 10});
                    console.log('force24 tracking response:', resp);
                }

                setTimeout(() => {
                    // give enough time for force24 tracking to run
                    this.save($bid, true);
                }, 1000);
            },
            decline($bid){ 
                this.save($bid, false);
            },
            save($bid, $isAccept = false){
                    
                let payload = { 
                    id: $bid.id, 
                    accepted: $isAccept,
                    declined: !$isAccept   

                };

                if (this.specialismChosen) {
                    payload.speciality_chosen = this.specialismChosen;
                }

                let self = this;
                
                if ($isAccept){

                    this.acceptAvailability(payload)  
                        .then(() => {
                            this.proceed($isAccept);
                        })
                        .catch((err) => {
                            if (err.response) {
                            self.popupErrorMessage=err.response.data.message;
                            self.hasError = true;
                            }
                        });
                }else{

                    this.declineAvailability(payload)
                        .finally(this.proceed($isAccept));
                }

            },
            proceed($isAccept){


                this.getGigWithOffers({id: this.$attrs.id, useTrashedArtists: false}).then((resp) => {
                        this.item = resp;
                        this.bids = resp.bids;
                    }).finally(() => {                    
                        this.$notify("Availability " + ($isAccept ? 'accepted' : 'declined'));
                        this.isShowAccept = false;
                        this.isShowDecline = false;
                        setTimeout(() => {                       
                            this.$router.push('/gigs/' + this.$route.params.id + '/bookings'); 
                        }, 1000);
                    });
            },
            editBidFee($bid){
                this.selectedBid = $bid;
                this.showAmendBidFeeModal = true;
            },
            cancelModal(){
                this.showAmendBidFeeModal = false;
            },
            onBidFeeUpdated($bid){
                this.selectedBid = $bid;
                this.cancelModal();
                this.initData();
            },        
            forceRerender() {
                this.getFavesLists().then((resp) => {
                    this.compFaveslists = resp;
                    this.isLoading = true;
                    this.loadData();
                });
            },
            updatePrivateVenues($event) {
                this.privateVenues = $event;
            },
            setPrivateArtistIds(privateArtists) {
                return privateArtists.map((artist) => {
                    return artist.id;
                });
            },
            showCovers(bid) {
              if (!bid.artist.specialities.length) return false;
              if (!bid.artist.specialities[0].covers) return false;
              return bid.artist.specialities[0].covers.length !== 0
            },
            showGenres(bid) {
              if (!bid.artist.specialities.length) return false;
              if (!bid.artist.specialities[0].genres) return false;
              return bid.artist.specialities[0].genres.length !== 0
            },
            showVocals(bid) {
              if (!bid.artist.specialities.length) return false;
              if (!bid.artist.specialities[0].vocals) return false;
              return bid.artist.specialities[0].vocals.length !== 0
            }
        
        }
    }
</script>

<style lang="postcss" scoped>
	
    .bid-tabs a {
        @apply selection:border-transparent text-gray-500  hover:text-gray-700  hover:border-gray-200 whitespace-nowrap flex py-4 px-1 font-medium text-sm;
	} 

    .bid_tab_active > a {
        @apply border-b-2 border-indigo-500 text-indigo-500;
    }
	.disabled{
        @apply bg-gray-400 cursor-not-allowed;
    }
</style>