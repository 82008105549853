<template>
		<GigPageWrapper
			:gig-id="String(gigId)" 
			current-route="repeat"
			title="Repeat"
		>
			<InlineNotification v-if="!isGigCancelled" Heading="Offer the gig multiple times" class="p-0 m-0">
					<template slot="body">
						<p>Do you book the same gig regularly? Save time and effort with our repeat gig feature by offering the same artists the same gig on multiple dates. Simply review your dates before publishing new gigs.</p>
					</template> 
			</InlineNotification> 

			<InlineNotification v-else Heading="Access denied">
				<template slot="body">
					<p class="mt-2 text-justify">This feature is reserved for gigs that have not been cancelled. Please navigate to the gig details page using the component below if you wish to see the full detailed breakdown of this cancelled gig.</p>
					<router-link :to="'/gigs/' + gigId" class="flex items-center mt-1 bg-transparent w-48"><ArrowCircleLeftIcon class="mr-2" color="pink"/>Go to gig details page</router-link>
				</template> 
			</InlineNotification> 

			<template v-if="this.isLoaded && gig && !isGigCancelled">

				<!-- Only show if venue isn't on PAYG payment plan --> 
				<div v-show="(gig.venue.venue_subscription && gig.venue.venue_subscription.active_subscription.id !== 3) || gig.venue.payment_process == 'ryft'">

					<div v-show="gig && gig.bid_count && gig.status !== 'DRAFT'" class="mt-12">
						<div class="flex">
							<label class="font-bold text-sm text-gray-700">Repeat as published</label>
							<t-toggle v-model="repeatPublished" class="ml-3"/>
						</div>
						<p class="text-sm mt-2 mb-12 text-gray-600">When you choose this option, the gig(s) will be offered to the same artists who received the offer from the original gig.</p>
					</div>

					<div v-show="gig && gig.status !== 'DRAFT'" class="mt-12">
						<div class="flex">
							<label class="font-bold text-sm text-gray-700">Repeat as draft</label>
							<t-toggle v-model="repeatDraft" class="ml-3"/>
						</div>
						<p class="text-sm mt-2 mb-12 text-gray-600">When you choose this option, the repeated gig(s) will be set up as draft (no offers will be sent to artists at this stage).</p>
					</div>

					<div v-show="gig && gig.bookings_count && (gig.status === 'CONFIRMED' || gig.status === 'SIGNED_OFF' || gig.status === 'NEEDS_SIGNOFF')" class="mt-12">
						<div class="flex">
							<label class="font-bold text-sm text-gray-700">Repeat and 'FAST BOOK' artists</label>
							<t-toggle v-model="repeatBooked" class="ml-3"/>
						</div>
						<p class="text-sm mt-2 mb-12 text-gray-600">
							When you choose this option, we will 'FAST BOOK' the artist from the current gig to the repeated gig(s). 
							Please make sure you have checked the availability of the artist before you attempt this.
						</p>
					</div>
				</div>

				<!-- Stage 1 -->
				<div v-if="!stagetwo">
					<SelectRepeatDates
						:gig="gig"
						:frequency-label="getFrequency"
						@update="onRepeatDataUpdate"
						@confirm="onRepeatDatesGenerated"
					/>
				</div>
				<!-- Stage 2 -->
				<div v-else>
					<ConfirmRepeatDates
						:repeat-dates="repeatData.repeats"
						:frequency="repeatData.frequency"
						:frequency-label="getFrequency"
						:is-multiple="repeatData.isMultiple"
						:start-date="repeatData.selectedStart"
						:repeatPublished="repeatPublished"
						:repeatBooked="repeatBooked"
						@cancel="cancelRepeats"
						@confirm="showConfirmation"
					/>
				</div>
			</template>

			<!-- Modals -->
			<template v-if="gig && !isGigCancelled">
				<RepeatConfirmModal 
					:is-open="showRepeatConfirmation"
					:gig-id="gigId"
					:dates="checkedDates"
					:repeatStatus="repeatType"
					@closed="onModalClosed"
					@confirmed="onModalConfirmed"
					@error="onModalError"
				/>
			</template>

			<NotificationSmall ref="notification" />
			<NoDataMsgWrapper v-if="!this.isLoaded && !isGigCancelled">Loading repeat gig page. Please wait.</NoDataMsgWrapper>

		</GigPageWrapper>
</template>

<script> 

import NotificationSmall from '@/components/notifications/small.vue'; 
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue';  
import InlineNotification from '@/components/notifications/inline.vue';
import RepeatConfirmModal from '@/components/modal/gigs/RepeatConfirm.vue'; 
import SelectRepeatDates from '@/components/gigs/repeat/SelectRepeatDates';  
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import ConfirmRepeatDates from '@/components/gigs/repeat/ConfirmRepeatDates.vue';  
import { ArrowCircleLeftIcon } from "@vue-hero-icons/outline";
import NProgress from 'nprogress';
import { TToggle } from 'vue-tailwind/dist/components'
import { paymentsMethods } from '@/state/helpers'; 

export default {
    name: "GigRepeat",
    components:{
		GigPageWrapper,
		SelectRepeatDates,
		ConfirmRepeatDates,
		NotificationSmall,
		InlineNotification,
		RepeatConfirmModal,
		ArrowCircleLeftIcon,
		NoDataMsgWrapper,
		TToggle
	}, 
	props:['gigId'],
    data (){
		return {  
			isLoaded: false,
			stagetwo: false,        
			checkedDates: [],
			showRepeatConfirmation: false,
			repeatData:{
				recurrence: 0,
				frequency: 0,
				isMultiple:false,
				repeats:[],
				selectedStart: Date.now()
			},
			sub_plan: [],
			gig:null,
			isLoading:false,
			repeatDraft: true,
			repeatPublished: false,
			repeatBooked: false
		}
    }, 
	watch: {
		repeatDraft($is_checked){ 
			if ($is_checked){
				this.repeatPublished = false;
				this.repeatBooked = false;
			}
		},
		repeatPublished($is_checked){ 
			if ($is_checked){
				this.repeatDraft = false;
				this.repeatBooked = false;
			}
		},
		repeatBooked($is_checked){ 
			if ($is_checked){
				this.repeatPublished = false;
				this.repeatDraft = false;
			}
		},
	},
	computed: { 
		getFrequency: function(){
			if (this.repeatData.recurrence == 2)
				return 'day';
			else if (this.repeatData.recurrence == 3)
				return 'week';
			else if (this.repeatData.recurrence == 4)
				return 'fortnight';
			else if (this.repeatData.recurrence == 5)
				return 'month'
					else if (this.repeatData.recurrence == 6)
				return 'date'
			else return 'day'
		},	
		isGigCancelled(){
			return this.$store.getters['gig/isCurrentGigCancelled']
		},	
		repeatType() {
			return this.repeatDraft ? 'REPEAT_DRAFT' : this.repeatPublished ? 'REPEAT_OFFERS_PUBLISHED' : 'REPEAT_BOOKINGS'
		}	
    },
		
    methods:{
		...paymentsMethods,
		onRepeatDataUpdate(repeatData){
			this.repeatData = { ...repeatData }
		},
        showConfirmation(data){
			this.checkedDates = [ ...data.confirmedDates ]
			if (this.checkedDates.length > 0){
				this.showRepeatConfirmation = true;
			}
        },
		onModalClosed(){
			this.checkedDates = []
			this.showRepeatConfirmation = false;
		},
		onModalConfirmed(){
			this.$refs.notification.createNotification(`This gig has been successfully repeated`, false);
			this.bannerInfo();
			this.onModalClosed();
			this.cancelRepeats();
		},
		onModalError($err){
			this.$refs.notification.createNotification($err.response.data.message, true);
			this.onModalClosed();
		},
		cancelRepeats(){
			this.repeatData.recurrence = 0;
			this.repeatData.frequency = 0;
			this.repeatData.isMultiple = false;
			this.repeatData.repeats = [];
			this.repeatData.selectedStart = Date.now();
			//
			this.stagetwo = false;
		},
		onRepeatDatesGenerated(repeatData){
			this.onRepeatDataUpdate(repeatData)
			this.stagetwo = true;
		},
		loadData() {
			NProgress.start();
			this.isLoading = true;
			this.$store.dispatch('gig/getGigSummary', this.gigId)
			.then((response) => {
				this.gig = response;
				this.bannerInfo();

				if (this.gig.venue.active_subscription && this.gig.venue.active_subscription.id !== 3 && this.gig.status !== 'DRAFT') {
					this.repeatDraft = false;
					this.repeatPublished = true;
				}

				this.isLoading = false;
				this.isLoaded = true;
				NProgress.done();
			})
		},
		async bannerInfo() {
			if (this.gig.venue.payment_process == "ryft") {

				await this.getVenueActiveSub({ venue_id: this.gig.venue.id })
                        .then((response) => {
                            this.sub_plan = response.data;
                        })

				let payment_type = this.gig.venue.payg_charged_and_billed_monthly ? 'payg_charged_and_billed_monthly' : 'payg';

				let selected_venue = {
					credits_remaining: this.gig.venue.active_tokens.length,
					id: this.gig.venue.id,
					name: this.gig.venue.name,
					expiry_year: this.gig.venue.expiry_year,
					expiry_month: this.gig.venue.expiry_month,
					payment_process: this.gig.venue.payment_process,
					currency_id: this.gig.venue.currency.id,
				payment_type: payment_type,
				has_subscription: this.sub_plan && this.sub_plan.subscription_plan && this.sub_plan.subscription_plan.id !== 1 ? true : false
				}

				this.$store.dispatch('account/updateVenueInfo', selected_venue);
			}
		}
    },
	mounted(){
		if (this.gigId)
		{
			this.loadData();
		}
	}
}
</script>